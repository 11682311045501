/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector } from "react-redux"

// import "./../../assets/theme-1/index.scss";
import "./../../assets/theme/index.scss"

import Header from "./header"
import Footer from "./footer"
import FixIcons from "./fix-icons"
import { Helmet } from "react-helmet"

import {
  HeaderQuery,
  FooterSectionQuery,
  CallBackFormQuery,
} from "../../prismic/staticQueries"

const Layout = ({ children, head = true }) => {
  const headerData = HeaderQuery()
  const footerData = FooterSectionQuery()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const {
    // logo,
    // body,
    callback_icon,
    callback_title,
    callback_form_image,
    chat_icon,
    chat_title,
    open_live_account_title,
    open_live_account_icon,
    open_live_account_url,
  } = headerData.dataRaw
  console.log(chat_title, chat_icon, "FIX ICONS DATA", headerData.dataRaw)

  const callback_form = CallBackFormQuery()

  const callback = {
    title: callback_title,
    icon: callback_icon,
    form: callback_form,
    formImage: callback_form_image,
  }
  const chat = { title: chat_title, icon: chat_icon }
  const liveAccount = {
    title: open_live_account_title,
    icon: open_live_account_icon,
    link: open_live_account_url,
  }

  return (
    <>
      <>
        <base target="_parent" />
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://accuindex.com/Content/img/favicon.png"
          />
          <base target="_parent" />

          <script async src="https://www.googletagmanager.com/gtag/js?id=G-1NG34EDE9N"></script>
          <script>
            {
              `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-1NG34EDE9N');`
            }
          </script>

          {/* <script src="https://v2.zopim.com/?4QNfK2zxJsGykOSioeH898I9DlkYa2Db"></script> */}
        </Helmet>
        {head && <Header {...headerData.data} />}
        
        <main>
          {children}
          {/* <FixIcons callback={callback} chat={chat} liveAccount={liveAccount} /> */}
        </main>
        <Footer {...footerData} />
      </>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
