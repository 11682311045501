import React, { useState } from "react"
import { connect } from "react-redux"
import TopBar from "./top-bar"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { Link } from "gatsby"

const HeaderSection = params => {
  const { logo_image, body } = params

  const [activeItem, setActiveItem] = useState(null)

  const toggleMenu = index => {
    setActiveItem(activeItem === index ? null : index)
  }

  return (
    <>
      <section className="header header--2">
        <TopBar {...params} />
        <div className="menu_area menu1">
          <div className="container">
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand href="#"  className="pr-4">
                
                <Link to={'/'} >
                  <img src={logo_image.url} alt="" style={{width: '115px', height: '83px'}} />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="">
                  <div className="nav-item dropdown">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-
                      toggle="collapse"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></button>
                  </div>

                  {body.map((menue, index) => (
                    <div key={"keys-" + index}>
                      {menue.items.length === 0 && (
                        <div
                          className="nav-item dropdown"
                          key={"upper-key-" + index}
                        >
                          <Link
                            key={"upper-key-ddf-" + index}
                            className="nav-link homeMenue"
                            to={menue.primary.menu_link.text}
                          >
                            {" "}
                            {menue.primary.menu_name.text}
                          </Link>
                        </div>
                      )}

                      {menue.items.length > 0 && (
                        <NavDropdown
                          key={"dropdown-key-" + index}
                          title={menue.primary.menu_name.text}
                          id="collasible-nav-dropdown"
                          onToggle={() => {
                            toggleMenu(index)
                          }}
                          // onMouseEnter={() => setActiveItem(index)}
                          // onMouseLeave={() => setActiveItem(index)}
                          show={activeItem === index}
                          className={`${"mainMenue menu-arrow"}`}
                        >
                          <ul
                            className="navbar-nav"
                            style={{ display: "block" }}
                          >
                            {menue.items.map((sub_menue, subMenueIndex) => (
                              <div key={"sdfsdfs-" + subMenueIndex}>
                                {
                                  (sub_menue.open_external === true && (
                                    <a
                                      key={"inner-link-key-" + subMenueIndex}
                                      className="dropdown-item subMenue"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={sub_menue.sub_menu_link.text}
                                    >
                                      {" "}
                                      {sub_menue.sub_menu_name.text}{" "}
                                    </a>
                                  )) ||
                                    (sub_menue.open_external === false && (
                                      <Link
                                        key={"inner-link-key-" + subMenueIndex}
                                        className="dropdown-item subMenue"
                                        to={sub_menue.sub_menu_link.text}
                                      >
                                        {" "}
                                        {sub_menue.sub_menu_name.text}{" "}
                                      </Link>
                                    ))

                                  // <NavDropdown.Item className="">
                                  // </NavDropdown.Item>
                                }
                              </div>
                            ))}
                          </ul>
                        </NavDropdown>
                      )}
                    </div>
                  ))}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(HeaderSection)
