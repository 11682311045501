import React from "react"
import { connect, useSelector } from "react-redux"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

const TopBar = (params = {}) => {
  const language = useSelector(state => state.language)

  const {
    academy_icon,
    academy_label,
    academy_link,

    client_portal_icon,
    client_portal_label,
    client_portal_link,

    demo_account_icon,
    demo_account_label,
    demo_account_link,

    live_account_icon,
    live_account_label,
    live_account_link

  } = params

  return (
    <div className="top_bar d-flex align-items-center text-blue-dark">
      <Container>
        <Row>
          <Col md={12}>
            <div className="d-flex topbar_content justify-content-between">
              <div className="top_bar_social">
                <ul>
                  <li>
                    <Link className="smallIcon" to={academy_link.text}>
                      <img alt="ddd" src={academy_icon.url} />{" "}
                      {academy_label.text}
                    </Link>
                  </li>
                  <li>
                    <a
                      className="smallIcon"
                      href={client_portal_link.text}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="ddd" src={client_portal_icon.url} />{" "}
                      {client_portal_label.text}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="top_bar_social">
                <ul>
                  <li>
                    <a
                      className="smallIcon"
                      href={demo_account_link.text}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="ddd" src={demo_account_icon.url} />{" "}
                      {demo_account_label.text}
                    </a>
                  </li>
                  <li>
                    <Link className="smallIcon" to={live_account_link.text}>
                      <img alt="ddd" src={live_account_icon.url} />{" "}
                      {live_account_label.text}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(TopBar)
