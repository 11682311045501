import React, { useState } from "react"
import { connect, useSelector } from "react-redux"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { PrismicRichText } from "../shared"

const Footer = params => {
  console.log(params)

  const {
    body,
    dispatch,
    language,
    mian_content,
    office_heading_label,
    offices_box,
    overlay_text,
    reserved_label,
    social_icons_box,
    website_boxes,
  } = params
  const [activeOffice, setActiveOffice] = useState(0)

  return (
    <footer className="mt-5">
      <div className="py-5 footer-menu">
        <Container>
          <Row>
            {body.map((item, indbody) => (
              <Col key={"ddd-" + indbody} lg={2} md={6} sm={6} className="mt-3">
                <h4 className="my-3">{item.primary.menu_label[0].text}</h4>
                <div className="footer-links">
                  {item.items.map((sub_menu, subIndex) => (
                    <div className="my-2" key={"ddd-" + subIndex}>
                      {(sub_menu.open_external === true && (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={sub_menu.sub_menu_link[0].text}
                        >
                          {sub_menu.sub_menu_label[0].text}
                        </a>
                      )) ||
                        (sub_menu.open_external === false && (
                          <a to={sub_menu.sub_menu_link[0].text} href={sub_menu.sub_menu_link[0].text}>
                            {sub_menu.sub_menu_label[0].text}
                          </a>
                        ))}
                    </div>
                  ))}
                </div>
              </Col>
            ))}

            <Col lg={2} md={6} sm={6} className="mt-3">
              <h4 className="my-3">{office_heading_label[0].text}</h4>
              {offices_box.map((office, index) => (
                <div className="mb-3" key={"ddd-" + index}>
                  <img
                    alt="ddd"
                    src={office.location_icon.url}
                    className="location-selector"
                    onClick={() => setActiveOffice(index)}
                    onMouseOver={() => setActiveOffice(index)}
                    onFocus={() => setActiveOffice(index)}
                  />
                </div>
              ))}
            </Col>
            <Col lg={4} md={6} sm={6} className="mt-3">
              <div className="text-gray my-3">
                <div className="mb-2">
                  <img
                    alt="ddd"
                    height="21px"
                    src={offices_box[activeOffice].location_icon.url}
                  />
                  <span className="mx-2">
                    {offices_box[activeOffice].location_title[0].text}
                  </span>
                </div>
                <div className="footer-office">
                  <PrismicRichText
                    data={offices_box[activeOffice].location_description}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white text-light text-sm pt-3 line-height-sm">
        <Container>
          <Row>
            <Col md={12} className="footer_data">
              <PrismicRichText data={mian_content} linksTarget="_blank" />
              {/* <div className="text-center">
                {website_boxes.map((box, index) => (
                  <a
                    href={box.web_link[0].text}
                    key={"dsdf-" + index}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={box.web_icon.url}
                      alt=""
                      className="px-4 pt-2 pb-4"
                      height="80px"
                    />
                  </a>
                ))}
              </div> */}
            </Col>
          </Row>
          <div className="border-top pt-5 pb-4 d-flex justify-content-between copy-right text-blue-dark">
            <p>{reserved_label[0].text}</p>

            {/* <div>
              {social_icons_box.map((icon, index) => (
                <a
                  key={"link-" + index}
                  href={icon.icon_link[0].text}
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark"
                >
                  <span
                    className={icon.icon_class[0].text}
                  ></span>
                </a>
              ))}
            </div> */}
          </div>
        </Container>
        <Row className="over-text-footer">
          <PrismicRichText data={overlay_text} />
        </Row>
      </div>
    </footer>
  )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(Footer)
